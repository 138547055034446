
import { defineComponent, ref, Ref } from 'vue';
import {
    ListHeaderItem, listPagination, RequestData
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import modelDialog, { ModelForm } from '@/components/view/super/model-dialog';

const headers: Array<ListHeaderItem> = [{
    name: 'VersionName',
    label: WordList.TabelVersionMangeName
}, {
    name: 'Version',
    label: WordList.TabelVersionMangeNumber
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];

export default defineComponent({
    components: {
        listPagination,
        addButton,
        modelDialog
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'versionmanages',
            param: {}
        });

        const isShowModelDialog = ref(false);
        const initForm: Ref<ModelForm> = ref({
            VersionName: '',
            VersionNumber: ''
        });
        const operateType: Ref <OperateType> = ref('add');

        const add = () => {
            initForm.value = {
                VersionName: '',
                VersionNumber: ''
            };
            operateType.value = 'add';
            isShowModelDialog.value = true;
        };

        const edit = (listItem: ModelForm) => {
            initForm.value = listItem;
            operateType.value = 'edit';
            isShowModelDialog.value = true;
        };

        return {
            headers,
            updateToList,
            listRequestData,
            isShowModelDialog,
            initForm,
            add,
            edit,
            operateType
        };
    }
});
