import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shell" }
const _hoisted_2 = { class: "search-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_button = _resolveComponent("add-button")
  const _component_list_pagination = _resolveComponent("list-pagination")
  const _component_model_dialog = _resolveComponent("model-dialog")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_add_button, {
        class: "btn-right",
        onClick: _ctx.add
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_list_pagination, {
      headers: _ctx.headers,
      requestData: _ctx.listRequestData,
      update: _ctx.updateToList,
      showCheck: false,
      "show-default-action": ['edit','delete'],
      deleteUrl: "deleteversionmanage",
      onEdit: _ctx.edit
    }, null, 8, ["headers", "requestData", "update", "onEdit"]),
    (_ctx.isShowModelDialog)
      ? (_openBlock(), _createBlock(_component_model_dialog, {
          key: 0,
          onSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateToList++)),
          "init-form": _ctx.initForm,
          "operate-type": _ctx.operateType,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowModelDialog=false))
        }, null, 8, ["init-form", "operate-type"]))
      : _createCommentVNode("", true)
  ]))
}